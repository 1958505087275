.card-problem-solution {
  padding: 1rem;
  margin: 1rem 0rem;
}

.card-problem-solution .card-problem-solution-title {
  font-size: 1.25rem;
}

.card-problem-solution .card-problem-solution-description {
  margin: 0rem;
}
