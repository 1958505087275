.featured-work-title {
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #6e6e73;
  flex-grow: 0;
  margin-right: 16px;
  margin-bottom: 1.25rem;
}

#featuredWork .card-work-experience {
  margin-bottom: 1.25rem;
}

@media (min-width: 576px) {
  #featuredWork .card-work-experience {
    margin-bottom: 1.5rem;
  }
}
