.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.orange-text {
  color: var(--orange);
}
.purple-text {
  color: #573d76;
}
.light-blue-text {
  color: var(--light-blue);
}
.blue-text {
  color: var(--dark-blue);
}

.mb-section {
  margin-bottom: 80px;
}

.socialIcon > * {
  width: 50px;
}

.screenshots img {
  border: 1px solid #dedede;
  margin-bottom: 24px;
}
.screenshots iframe {
  border: 1px solid #dedede;
}

.large-emphasized-text {
  font-size: 56px;
  line-height: 56px;
  font-weight: 600;
  letter-spacing: 0.025em;
}
