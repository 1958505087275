button.nav-link.dropdown-toggle {
  border: none;
  background-color: inherit;
}

button.navbar-toggler {
  border: none;
  background-color: inherit;
}

nav.navbar {
  padding: 1rem 0rem;
}

nav.navbar .navbar-brand {
  font-size: 1.75rem;
  color: #1c2c39;
}

nav.navbar .nav-item {
  font-size: 1rem;
}

nav.navbar .nav-item .nav-link {
  color: #1c2c39;
}
