.dark-footer {
  background-color: #171f26;
}

.footer-links a {
  color: #ffffff;
}

footer {
  margin-top: 160px;
}
