button.btn {
  font-size: 18px;
  line-height: 21px;
  padding: 16px 24px;
  border-radius: 50px;
}

a.btn {
  font-size: 18px;
  line-height: 21px;
  padding: 16px 24px;
  border-radius: 50px;
}

.btn-link {
  background-color: transparent;
  border-color: transparent;
  color: #429fd8;
}

.btn.btn-primary {
  background-color: #171f26;
  border-color: #171f26;
  color: #ffffff;
}

.btn.btn-primary:hover {
  background-color: #565c61;
  border-color: #565c61;
}

.btn.btn-outline-primary {
  background-color: transparent;
  border-color: #171f26;
  color: #171f26;
}

.btn.btn-outline-primary:hover {
  background-color: #171f26;
  border-color: #171f26;
  color: #ffffff;
}

.btn .btn-icon {
  padding-left: 12px;
}
