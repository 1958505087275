.intro-header_large-text {
  font-size: 5rem;
  line-height: 5.3rem;
  font-weight: 600;
  letter-spacing: -0.015em;
}

.intro-header_description-text {
  color: var(--bs-gray-600);
  font-size: 1.25rem;
  line-height: 2.25rem;
  font-weight: 400;
  letter-spacing: normal;
}

@media (min-width: 576px) {
  .intro-header_large-text {
    font-size: 5rem;
    line-height: 5.3rem;
    font-weight: 600;
    letter-spacing: -0.015em;
  }

  .intro-header_description-text {
    font-size: 1.25rem;
    line-height: 2.25rem;
    font-weight: 400;
    letter-spacing: normal;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .intro-header {
    display: flex;
    align-items: center;
    background: url("../assets/ds-bw-bg.jpg");
    background-repeat: no-repeat;
    background-size: auto 500px;
    background-position-x: center;
    background-position-y: -26px;
    height: calc(100vh - 80px);
    min-height: 400px;
    max-height: 450px;
  }

  .intro-header_large-text {
    font-size: 4rem;
    line-height: 4.3rem;
    font-weight: 600;
    letter-spacing: -0.015em;
  }

  .intro-header_description-text {
    font-size: 1.25rem;
    line-height: 2.25rem;
    font-weight: 400;
    letter-spacing: normal;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .intro-header {
    background-size: auto 600px;
    background-position-x: center;
    background-position-y: -39px;
    height: calc(100vh - 80px);
    min-height: 500px;
    max-height: 550px;
  }

  .intro-header_large-text {
    font-size: 5rem;
    line-height: 5.3rem;
    font-weight: 600;
    letter-spacing: -0.015em;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .intro-header {
    background-size: auto 700px;
    background-position-x: center;
    background-position-y: -39px;
    height: calc(100vh - 80px);
    min-height: 600px;
    max-height: 650px;
  }

  .intro-header_large-text {
    font-size: 6rem;
    line-height: 6.3rem;
    font-weight: 600;
    letter-spacing: -0.015em;
  }

  .intro-header_description-text {
    font-size: 1.5rem;
    line-height: 2.5rem;
    font-weight: 400;
    letter-spacing: normal;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .intro-header {
    background-size: auto 800px;
    background-position-x: center;
    background-position-y: -39px;
    height: calc(100vh - 80px);
    min-height: 650px;
    max-height: 700px;
  }
  .intro-header_large-text {
    font-size: 6rem;
    line-height: 6.3rem;
    font-weight: 600;
    letter-spacing: -0.015em;
  }
}
