.skill-pill-list {
  display: flex;
  flex-wrap: wrap;
  padding: 0px;
  margin-left: -5px;
}

.skill-pill-list .skill-pill-list-item {
  list-style-type: none;
  padding: 4px 12px;
  border-radius: 50px;
  background-color: var(--bs-gray-200);
  color: inherit;
  margin: 5px;
  font-size: 1rem;
}
