.card.card-about-me-work-experience {
  border: none;
}

.card.card-about-me-work-experience .card-body {
  padding: 1rem 0rem;
}

a.card-work-experience:hover {
  text-decoration: none;
}

.card.card-work-experience {
  border-radius: 0;
  border: none;
  transition: opacity 1000ms ease, transform 450ms ease,
    -webkit-transform 450ms ease;
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  will-change: transform;
  text-decoration: none;
  height: 350px;
  background-size: 90% auto;
  background-repeat: no-repeat;
  background-position-y: 85%;
  background-position-x: center;
}

.card.card-work-experience:hover {
  box-shadow: 1px 1px 5px 1px rgb(0 0 0 / 20%);
  -webkit-transform: scale(1.01) translate3d(0, 0, 0) perspective(1px);
  -moz-transform: scale(1.01) translate3d(0, 0, 0) perspective(1px);
  -o-transform: scale(1.01) translate3d(0, 0, 0) perspective(1px);
  -ms-transform: scale(1.01) translate3d(0, 0, 0) perspective(1px);
  transform: scale(1.01) translate3d(0, 0, 0) perspective(1px);
}

.card.card-work-experience .card-body {
  padding: 32px 16px;
  text-align: center;
}
.card.card-work-experience .card-body .card-title {
  font-size: 2rem;
  line-height: 2.3rem;
  font-weight: 600;
}
.card.card-work-experience .card-body .card-text {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 7px;
  line-height: 1.25em;
}

@media (min-width: 576px) {
  .card.card-work-experience {
    height: 375px;
    background-size: 68%;
    background-repeat: no-repeat;
    background-position-y: 85%;
    background-position-x: center;
  }
  .card.card-work-experience .card-body .card-title {
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 600;
  }
}

@media (min-width: 768px) {
  .card.card-work-experience {
    height: 375px;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position-y: 81%;
    background-position-x: center;
  }
  .card.card-work-experience .card-body .card-title {
    font-size: 2.5rem;
    line-height: 2.75rem;
    font-weight: 600;
  }
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .card.card-work-experience {
    height: 350px;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 95%;
    justify-content: center;
  }

  .card.card-work-experience .card-body {
    padding: 32px;
    text-align: left;
  }

  .card.card-work-experience .card-body .card-title {
    font-size: 3.75rem;
    line-height: 3.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .card.card-work-experience .card-body .card-text {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 7px;
    line-height: 1.25em;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .card.card-work-experience {
    height: 375px;
    background-size: 45%;
    background-repeat: no-repeat;
    background-position-y: 54%;
    background-position-x: 93%;
    justify-content: center;
  }

  .card.card-work-experience .card-body {
    padding: 0px 0px 0px 80px;
    text-align: left;
  }

  .card.card-work-experience .card-body .card-title {
    font-size: 3.75rem;
    line-height: 3.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .card.card-work-experience .card-body .card-text {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
    line-height: 1.75em;
  }
}

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) {
  .card.card-work-experience {
    height: 400px;
    background-size: 45%;
    background-repeat: no-repeat;
    background-position-y: 54%;
    background-position-x: 90%;
    justify-content: center;
  }

  .card.card-work-experience .card-body {
    padding: 40px 40px 40px 80px;
    text-align: left;
  }

  .card.card-work-experience .card-body .card-title {
    font-size: 3.75rem;
    line-height: 3.75rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  .card.card-work-experience .card-body .card-text {
    font-size: 1.25rem;
    font-weight: 400;
    margin-bottom: 1.25rem;
    line-height: 1.75em;
  }
}
