.page-header-wrapper {
  height: 250px;
  margin-bottom: 60px;
  color: #fff;
  display: flex;
  align-items: center;
  background-size: 0%;
}

.page-header-content {
  flex-grow: 1;
}

.page-header-content-title {
  font-size: 5rem;
  line-height: 5.3rem;
  font-weight: 600;
  letter-spacing: -0.015em;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .page-header-wrapper {
    height: 250px;
    margin-bottom: 60px;
    background-size: auto 75%;
    background-position: 100% 60%;
    background-repeat: no-repeat;
  }

  .page-header-content-title-wrapper {
    height: 250px;
    display: flex;
    align-items: center;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .page-header-wrapper {
    height: 350px;
    margin-bottom: 60px;
    background-size: auto 25vw;
    background-position: 100% 62%;
    background-repeat: no-repeat;
  }

  .page-header-content-title-wrapper {
    height: 350px;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .page-header-wrapper {
    height: 400px;
    margin-bottom: 60px;
    background-size: auto 78%;
    background-position: 100% 62%;
    background-repeat: no-repeat;
  }

  .page-header-content-title-wrapper {
    height: 400px;
  }
}
